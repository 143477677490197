import axios from "axios";

const api = axios.create({
    // baseURL: 'http://localhost:3009/api'
    baseURL: `${process.env.REACT_APP_DEV_API_KEY}/auth`
});

export const adminLogin = async(value) => {
    const response = await api.post("/login", value)
    return response.data
}

// export const addRegistration = async (value) =>  api.post("/charge", value)

export const updateCharge = async (value) => api.put(`/charge/${value.id}`, value)

// export const deleteRegistration = async( value) => api.delete(`/charge/${value.id}`, value)


export default api;