// // routes
// import { PATH_AUTH } from '../routes/paths';
// // utils
// import axios from '../utils/axios';

// // ----------------------------------------------------------------------

// function jwtDecode(token) {
//   const base64Url = token.split('.')[1];
//   const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
//   const jsonPayload = decodeURIComponent(
//     window
//       .atob(base64)
//       .split('')
//       .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
//       .join('')
//   );

//   return JSON.parse(jsonPayload);
// }

// // ----------------------------------------------------------------------

// export const isValidToken = (accessToken) => {
//   if (!accessToken) {
//     return false;
//   }

//   const decoded = jwtDecode(accessToken);

//   const currentTime = Date.now() / 1000;

//   return decoded.exp > currentTime;
// };

// // ----------------------------------------------------------------------

// export const tokenExpired = (exp) => {
//   // eslint-disable-next-line prefer-const
//   let expiredTimer;

//   const currentTime = Date.now();

//   // Test token expires after 10s
//   // const timeLeft = currentTime + 10000 - currentTime; // ~10s
//   const timeLeft = exp * 1000 - currentTime;

//   clearTimeout(expiredTimer);

//   expiredTimer = setTimeout(() => {
//     alert('Token expired');

//     localStorage.removeItem('accessToken');

//     window.location.href = PATH_AUTH.login;
//   }, timeLeft);
// };

// // ----------------------------------------------------------------------

// export const setSession = (accessToken) => {
//   if (accessToken) {
//     console.log(accessToken, "setSession")
//     localStorage.setItem('accessToken', accessToken);

//     axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

//     // This function below will handle when token is expired
//     const { exp } = jwtDecode(accessToken); // ~3 days by minimals server
//     tokenExpired(exp);
//   } else {
//     localStorage.removeItem('accessToken');

//     delete axios.defaults.headers.common.Authorization;
//   }
// };


// import axios from 'axios';
// routes
import { PATH_AUTH } from '../routes/paths';
// utils
import axios from '../utils/axios';


// ----------------------------------------------------------------------

function jwtDecode(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
}

// ----------------------------------------------------------------------

export const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

// ----------------------------------------------------------------------

// export const tokenExpired = (exp) => {
//   // eslint-disable-next-line prefer-const
//   let expiredTimer;

//   const currentTime = Date.now();

//   // Test token expires after 10s
//   // const timeLeft = currentTime + 10000 - currentTime; // ~10s
//   const timeLeft = exp * 1000 - currentTime;

//   clearTimeout(expiredTimer);

//   expiredTimer = setTimeout(() => {
//     // alert('Token expired');

//     localStorage.removeItem('accessToken');

//     window.location.href = PATH_AUTH.login;
//   }, timeLeft);
// };

// ----------------------------------------------------------------------

// export const setSession = (accessToken) => {
//   console.log(accessToken, "accessToken")
//   if (accessToken) {
//     localStorage.setItem('accessToken', accessToken);
//     console.log(axios.defaults.headers.common.Authorization, "BEFORE")
//     axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
//     console.log(axios.defaults.headers.common.Authorization, "AFTER")
//     // delete axios.defaults.headers.common.Authorization;
//     // axios.defaults.headers.common.Authorization = `Bearer ${getLocalStorage('accessToken')}`;
//     // console.log(accessToken, "SET ITEM")
//     // console.log(getLocalStorage('accessToken'), "getLocalStorage('accessToken')")
//     // axios.interceptors.request.use((config) => {
//     //   console.log(accessToken, "INTERCEPT")
//     //   config.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
//     //   return config;
//     // });



//     // This function below will handle when token is expired
//     const { exp } = jwtDecode(accessToken); // ~3 days by minimals server
//     tokenExpired(exp);
//   } else {
//     localStorage.removeItem('accessToken');

//     delete axios.defaults.headers.common.Authorization;
//   }
// };


export const tokenExpired = (exp) => {
  let expiredTimer;

  const currentTime = Date.now();
  const timeLeft = exp * 1000 - currentTime; // Time left in milliseconds

  // Clear any previous timers to prevent multiple setTimeouts
  clearTimeout(expiredTimer);

  if (timeLeft <= 0) {
    // If the token has already expired, log out immediately
    handleTokenExpiry();
  } else {
    // Set a timeout to handle token expiry after timeLeft has passed
    expiredTimer = setTimeout(() => {
      handleTokenExpiry();
    }, timeLeft);
  }
};

// Helper function to handle token expiration
const handleTokenExpiry = () => {
  // Optionally alert the user before logging them out
  // alert('Your session has expired, please log in again.');

  // Remove the access token
  localStorage.removeItem('accessToken');

  // Redirect to the login page
  window.location.href = PATH_AUTH.login; // Adjust this path based on your app's routing
};


export const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // This interceptor ensures the token is always added before each request
    axios.interceptors.request.use((config) => {
      const token = localStorage.getItem('accessToken');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    });

    // Handle token expiry
    const { exp } = jwtDecode(accessToken);
    tokenExpired(exp); // Ensure this is implemented to refresh or handle expiration
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};



const headers = () => ({
  Authorization: `Bearer ${localStorage.getItem('accessToken')}`,

});
