import React from 'react'
import { PATH_AFTER_LOGIN } from '../config-global';
import { useAuthContext } from '../auth/useAuthContext';
import { PATH_AUTH} from './paths';
import LoadingScreen from '../components/loading-screen/LoadingScreen';


export default function LogRedir() {
    const { user } = useAuthContext();
// console.log(PATH_REGISTRATION.root, PATH_DASHBOARD.dashhome)

    if(!user?.username) {
        window.location.href = PATH_AUTH.login 
        return false;
    }




        window.location.href = PATH_AFTER_LOGIN
        
 

//    React.useEffect(() =>{
//         window.location.href = PATH_AFTER_LOGIN2
//     })

    return (
        <LoadingScreen/>
       
    )
 
}
