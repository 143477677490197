import axios from 'axios';
// config
import { HOST_API_KEY } from '../config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API_KEY });


axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // console.log(error, "inter--")
    // Handle the error here or perform any desired actions
    if (error.response) {
      console.log('API Error:', error.response.status);
    } else if (error.request) {
      console.log('API Error: Request failed');
    } else {
      console.log('API Error:', error.message);
    }

    return Promise.reject(error);
  }
);

// axiosInstance.interceptors.response.use(
//   (response) => response,
//   (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
// );

export default axiosInstance;
