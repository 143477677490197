
export const checkObjEquality = (obj1, obj2) => {
  const obj1Keys = Object.keys(obj1);
  return obj1Keys.every((key) => obj1[key] === obj2[key]);
}

export const convertToNaturalLanguage_old = (str) => {
  // Replace underscores with spaces
  const withSpaces = str.replace(/_/g, ' ');

  // Capitalize the first letter of each word
  const capitalized = withSpaces.replace(/\b\w/g, (match) => match.toUpperCase());

  return capitalized.toUpperCase();
}

export const convertToNaturalLanguage = (str) => {
  // Define a mapping of specific terms to their natural language equivalents
  if (typeof str !== 'string') {
    return ''; // Return an empty string or some default value if `str` is not valid
  }

  const rewordingMap = {
    'sch_major': 'Major',
    'sch_courses': 'Courses Taken',
    'sch_courses_next': 'Next Semester Courses',
    'sch_study_year': 'Year of Study',
    'sch_credits': 'Credits Earned',
    'sch_credits_next': 'Next Semester Credits',
    'sch_graduation_year': 'Expected Graduation Year',
    'sch_enrollment': 'Enrollment Status',
    'fea_passport_no': 'Passport Number',
    'fea_had_usvisa': 'Had US Visa',
    'fea_last_usvisa_issue': 'Last US Visa Issued On',
    'fea_last_usvisa_expiration': 'US Visa Expiration Date',
    'fea_last_usvisa_no': 'Last US Visa Number',
    'gen_skills_hobbies': 'Skills and Hobbies',
    'gen_personality': 'Personality',
    'gen_prev_work_travel': 'Previously Done Work and Travel',
    'gen_prev_itce': 'Previously Participated in ITCE',
    'gen_job_preference': 'Job Preference',
    'sa_accompanied': 'Accompanied by Someone',
    'sa_arrested': 'Arrested Before',
    'sa_pregnant': 'Pregnant',
    'gen_swim': 'Able to Swim',
    // Add more mappings as needed
  };

  // Use the mapping if it exists, otherwise fall back to the original transformation
  if (rewordingMap[str]) {
    return rewordingMap[str];
  } 
    // Replace underscores with spaces and capitalize the first letter of each word
    const withSpaces = str.replace(/_/g, ' ');
    return withSpaces.replace(/\b\w/g, (match) => match.toUpperCase());
  
};

export const getBuild = async () => {
  try {
    const b = await fetch("../../metadata.json")
      .then((response) => response.json())
      .then((data) => data)
      .catch((err) => err);
    return b;
  } catch (error) {
    console.log(error);
    return null;
  }
}



// module.exports ={
//   checkObjEquality,
//   convertToNaturalLanguage
// }