import { Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

// AUTH
export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));
export const RegisterPage = Loadable(lazy(() => import('../pages/auth/RegisterPage')));
export const VerifyCodePage = Loadable(lazy(() => import('../pages/auth/VerifyCodePage')));
export const NewPasswordPage = Loadable(lazy(() => import('../pages/auth/NewPasswordPage')));
export const ResetPasswordPage = Loadable(lazy(() => import('../pages/auth/ResetPasswordPage')));


// BLANK PAGE
export const BlankPage = Loadable(lazy(() => import('../pages/dashboard/BlankPage')));

// MAIN
export const Page500 = Loadable(lazy(() => import('../pages/Page500')));
export const Page403 = Loadable(lazy(() => import('../pages/Page403')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));

// DEMO COMPONENTS
// ----------------------------------------------------------------------



export const ContractPage = Loadable(lazy(() => import('../features/ContractPage')));
export const UsersPage = Loadable(lazy(() => import('../features/UsersPage')));
export const JobsPage = Loadable(lazy(() => import('../features/JobsPage')));
export const JobApplicationsPage = Loadable(lazy(() => import('../features/JobApplicationsPage')));
export const SponsorsPage = Loadable(lazy(() => import('../features/SponsorsPage')));
export const EmployersPage = Loadable(lazy(() => import('../features/EmployersPage')));

export const StudentsPage = Loadable(lazy(() => import('../features/StudentsPage')));
export const SeasonPage = Loadable(lazy(() => import('../features/SeasonPage')));
export const ProgramsPage = Loadable(lazy(() => import('../features/ProgramsPage')));
export const SchoolsPage = Loadable(lazy(() => import('../features/SchoolsPage')));
export const RegistrationsPage = Loadable(lazy(() => import('../features/RegistrationsPage')));
export const DashStatPage = Loadable(lazy(() => import('../features/DashStatPage')));
export const ChargesPage = Loadable(lazy(() => import('../features/ChargesPage')));
export const ChargeTypesPage = Loadable(lazy(() => import('../features/ChargeTypesPage')));
export const PaymentsPage = Loadable(lazy(() => import('../features/PaymentsPage')));
export const NoticePage = Loadable(lazy(() => import('../features/NoticePage')));
export const MailerPage = Loadable(lazy(() => import('../features/MailerPage')));


export const AdminLoginPage = Loadable(lazy(() => import('../auth_/AdminLoginPage')));

