import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import RoleBasedGuard from '../auth/RoleBasedGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import MainLayout from '../layouts/main';
import SimpleLayout from '../layouts/simple';
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import {
  // Auth
  LoginPage,
  RegisterPage,
  VerifyCodePage,
  NewPasswordPage,
  ResetPasswordPage,

  BlankPage,

  //
  Page500,
  Page403,
  Page404,

 
  JobsPage,
  JobApplicationsPage,
  SponsorsPage,
  EmployersPage,
  StudentsPage,
  SeasonPage,
  ProgramsPage,
  SchoolsPage,
  RegistrationsPage,
  ChargesPage,
  AdminLoginPage,
  PaymentsPage,
  ChargeTypesPage,
  UsersPage,
  ContractPage,
  DashStatPage,
  NoticePage,
  MailerPage
} from './elements';
import LogRedir from './LogRedir';


// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <LoginPage /> },
        { path: 'register-unprotected', element: <RegisterPage /> },
        {
          element: <CompactLayout />,
          children: [
            { path: 'reset-password', element: <ResetPasswordPage /> },
            { path: 'new-password', element: <NewPasswordPage /> },
            { path: 'verify', element: <VerifyCodePage /> },
          ],
        },
      ],
    },

    // Auth_
    {
      path: 'auth_',
      children: [
        {
          path: 'login',
          element: (
            // <GuestGuard>
              <AdminLoginPage />
            // </GuestGuard>
          ),
        },
    
      ],
    },

    // Dashboard
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          
             <DashboardLayout />
       
         
        </AuthGuard>
      ),
      children: [
        { element: <LogRedir/>, index: true },
 
        
        {
          path: 'students',
          children: [
            { element: <Navigate to="/dashboard/students/list" replace />, index: true },
            // { path: 'profile', element: <UserProfilePage /> },
            // { path: 'cards', element: <UserCardsPage /> },
            { path: 'list', element: <StudentsPage /> },
  
          ],
        }, {
          path: 'registrations',
          children: [
            { element: <Navigate to="/dashboard/registrations/list" replace />, index: true },
            // { path: 'profile', element: <UserProfilePage /> },
            // { path: 'cards', element: <UserCardsPage /> },
            { path: 'list', element: <RegistrationsPage /> },
            // { path: 'new', element: <UserCreatePage /> },
            // { path: ':name/edit', element: <UserEditPage /> },
            // { path: 'account', element: <UserAccountPage /> },
          ],
        },
        {
          path: 'transactions',
          children: [
            { element: <Navigate to="/dashboard/transactions/list" replace />, index: true },
            { path: 'charges', element: <ChargesPage /> },
            { path: 'payments', element: <PaymentsPage /> },

          ],
        },
    
   
   
       // { path: 'permission-denied', element: <PermissionDeniedPage /> },
        { path: 'blank', element: <BlankPage /> },

        // MY DASH ROUTER
        { path: 'employers', element: <EmployersPage /> },
        { path: 'notice', element: <NoticePage /> },
        { path: 'chargetypes', element: <ChargeTypesPage /> },
        { path: 'jobs', element: <JobsPage /> },
        { path: 'jobapplications', element: <JobApplicationsPage /> },
        { path: 'programs', element: <ProgramsPage /> },
        { path: 'sponsors', element: <SponsorsPage /> },
        { path: 'season', element: <RoleBasedGuard hasContent={true} roles={['super-admin', 'admin', 'user']}><SeasonPage /></RoleBasedGuard> },
        { path: 'schools', element: <SchoolsPage /> },
        { path: 'users', element: <UsersPage /> },
        { path: 'contract', element: <ContractPage /> },
        { path: 'overview', element: <DashStatPage /> },
        { path: 'mailer', element: <MailerPage /> },

      ],


    },

    // Main Routes
    {
      element: <MainLayout />,
      children: [
        { element: <Navigate to="/dashboard" replace />, index: true }
      ],
    },

    {
      element: <CompactLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
